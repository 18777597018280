import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsWorkingLight,
} from '@/components/ui/Chubbs/ChubbsWorking/ChubbsWorking.light';
import {
  ChubbsWorkingDark,
} from '@/components/ui/Chubbs/ChubbsWorking/ChubbsWorking.dark';

export const ChubbsWorking: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={ChubbsWorkingLight}
    DarkChubbs={ChubbsWorkingDark}
    {...props}
  />
));
